const distanceBetween = (lat1, lng1, lat2, lng2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const toRadians = (degree) => degree * (Math.PI / 180);

  // Convert coordinates to radians
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lng2 - lng1);
  const radLat1 = toRadians(lat1);
  const radLat2 = toRadians(lat2);

  // Haversine's formula
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(radLat1) * Math.cos(radLat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c * 1000; // Distance in meters

  if (distance < 1000) {
    return `${Math.round(distance / 50) * 50}m`;
  } else {
    return `${(distance/1000).toFixed(1)}km`;
  }
}

export { distanceBetween }
