// IMAGES
export const yanportLong = 'https://res.cloudinary.com/vesta-home/image/upload/v1687262962/assets/images/logos/yanport_full.webp';
export const meilleursAgents = 'https://res.cloudinary.com/vesta-home/image/upload/v1687267278/assets/images/logos/meilleursagents.webp';
export const expertsShakingHands = 'https://res.cloudinary.com/vesta-home/image/upload/w_600/v1700131092/assets/revamp/photos/experts/esperts_shaking_hands.webp';
export const defaultPropertyPicture = 'https://res.cloudinary.com/vesta-home/image/upload/v1675426523/assets/Napoleon/Images/noPicture.webp';
export const firstEstimResult = 'https://res.cloudinary.com/vesta-home/image/upload/v1721998233/assets/Napoleon/Images/first_estim_result.webp';
export const propertyCardCarousel = 'https://res.cloudinary.com/vesta-home/image/upload/w_600/v1716297562/assets/Napoleon/Images/AdvertsBanner.webp'
export const buyerAlertCreatorLeftPanel = 'https://res.cloudinary.com/vesta-home/image/upload/v1727943329/assets/Napoleon/Images/buyer_alert_left_panel.webp'
export const buyerAlertCreatorLeftPanelStep2 = 'https://res.cloudinary.com/vesta-home/image/upload/v1727943329/assets/Napoleon/Images/buyer_alert_left_panel2.webp'
export const ellipseFuStep1 = 'https://res.cloudinary.com/vesta-home/image/upload/v1729867382/assets/Napoleon/Images/ellipse_fu_1.webp'
export const ellipseFuStep2 = 'https://res.cloudinary.com/vesta-home/image/upload/v1729867382/assets/Napoleon/Images/ellipse_fu_2.webp'
export const ellipseFuStep3 = 'https://res.cloudinary.com/vesta-home/image/upload/v1729867382/assets/Napoleon/Images/ellipse_fu_3.webp'
export const productFuStep4 = 'https://res.cloudinary.com/vesta-home/image/upload/v1729867391/assets/Napoleon/Images/product_fu.webp'
